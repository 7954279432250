import { useState, useEffect } from "react";
import { BrowserRouter, useRoutes, Navigate } from "react-router-dom";
import Layout from "./Layout/Layout";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Report from "./pages/Report/Report";
import Verify from "./component/verify/verify";
import Initializer from "./global/Initializer";
import MySnackbar from "./component/snakbar/snakbar";
import { Provider } from "react-redux";
import DotLottie from "../src/intro/dot-lottie/dot-lottie";
import styles from "./App.module.css";
import IntroAnimation from "../src/intro/Animation - 1717500961843 (1).json";
import store from "./store/index";
import Edit from "./pages/Edit/Edit";
import All from "./pages/AllProjects/AllProjects";
import QueryProjects from "./pages/QueryProjects/QueryProjects";
import ReportUser from "./pages/ReportUser/ReportUser";
import FAQ from "./pages/FAQ/Faq";
import EditUsers from "./pages/EditUsers/EditUsers";
import AllUsers from "./pages/AllUsers/AllUsers";
import TimeTracking from "./pages/TimeTracking/time-tracking";
import Approve from "./pages/Assign Project To Member/AssignProjectToMember";
import Approvetime from "./pages/Approve/Approve";
import CreateProject from "./pages/CreatPro/CreateProject";
import CreateUsers from "./pages/CreateUsers/CreateUsers";
import OnlineUser from "./pages/OnlineUser/OnlineUser";
import Profile from "./pages/Profile/Profile";
import Reset from "./pages/Reset/Reset";
import QueryProjectsUser from "./pages/QueryProjjectsUser/QueryProjectsUser";
import ManualTracker from "./pages/TimeTracking/time-tracking-manual";
import TimeTrackingData from "./pages/TimeTrackingData/TimeTrackingData";
import WelcomeScreen from "./pages/welcome-screen/welcome-screen";
import ForgetPass from "pages/ForgetPass/ForgetPass";
import Change_temp_pass from "pages/Change_temp_pass/Change_temp_pass";

function App() {
  const [showIntroGif, setShowIntroGif] = useState(false);
  const [terminateIntro, setTerminateIntro] = useState(false);

  useEffect(() => {
    const introShown = sessionStorage.getItem("introShown");

    if (introShown !== "true") {
      setShowIntroGif(true);
      sessionStorage.setItem("introShown", "true");
    }
  }, []);

  useEffect(() => {
    if (!showIntroGif) return;

    const timer = setTimeout(() => {
      setShowIntroGif(false);
    }, 4250);

    return () => clearTimeout(timer);
  }, [showIntroGif]);

  useEffect(() => {
    if (!showIntroGif) {
      const introDiv = document.querySelector(".intro");
      if (introDiv) {
        introDiv.classList.add(styles.introDivFade);
      }
      const timer = setTimeout(() => {
        setTerminateIntro(true);
      }, 750);
      return () => clearTimeout(timer);
    }
  }, [showIntroGif]);

  // Protected Route pages
  const ProtectedRoute = ({ children }: any) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return <Navigate to="/Login" />;
    }
    return children;
  };

  function Router() {
    const routes = [
      {
        menuButtonVisible: true,
        protected: true,
        path: "/User",
        element: <WelcomeScreen />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "",
        element: <Login />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin",
        element: <WelcomeScreen />,
      },

      {
        menuButtonVisible: false,
        protected: false,
        path: "/ForgotPassword",
        element: <ForgetPass />,
      },
      {
        menuButtonVisible: false,
        protected: false,
        path: "/Login",
        element: <Login />,
      },
      {
        menuButtonVisible: false,
        protected: false,
        path: "/Register",
        element: <Register />,
      },
      {
        menuButtonVisible: false,
        protected: false,
        path: "/Change_temp_pass",
        element: <Change_temp_pass />,
      },
      {
        menuButtonVisible: true,
        protected: false,
        path: "/FAQ",
        element: <FAQ />,
      },
      {
        menuButtonVisible: false,
        protected: false,
        path: "/verify",
        element: <Verify />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/User/ManualTracker",
        element: <ManualTracker />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/User/Profile",
        element: <Profile />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "User/Tracker",
        element: <TimeTracking />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/User/MyReport",
        element: <ReportUser />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/User/MyReports",
        element: <QueryProjectsUser />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/ManualTracker",
        element: <ManualTracker />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/edit-project",
        element: <Edit />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/All",
        element: <All />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/AllUsers",
        element: <AllUsers />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/UserReport",
        element: <QueryProjects />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/ProjectReport",
        element: <TimeTrackingData />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/edit-member",
        element: <EditUsers />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/Approve",
        element: <Approvetime />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/AssignProject",
        element: <Approve />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/CreateProject",
        element: <CreateProject />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/CreateUser",
        element: <CreateUsers />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/OnlineUser",
        element: <OnlineUser />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/Profile",
        element: <Profile />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/Tracker",
        element: <TimeTracking />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/Reset",
        element: <Reset />,
      },
      {
        menuButtonVisible: true,
        protected: true,
        path: "/Admin/Report",
        element: <Report />,
      },
    ];

    const routing = useRoutes(
      routes.map((x) => {
        return {
          ...x,
          element: x.protected ? (
            <ProtectedRoute>
              <Layout menuIconVisible={x.menuButtonVisible}>{x.element}</Layout>
            </ProtectedRoute>
          ) : (
            <Layout menuIconVisible={x.menuButtonVisible}>{x.element}</Layout>
          ),
        };
      })
    );

    return routing;
  }

  return (
    <>
      {!terminateIntro && showIntroGif ? (
        <div className={`${styles.introDiv} intro`}>
          <DotLottie animation={IntroAnimation} />
          <div className={styles.logo}></div>
          <div className={styles.tit}>TickTrackIT</div>
        </div>
      ) : (
        <Provider store={store}>
          <BrowserRouter>
            <Initializer>
              <Router />
              <MySnackbar />
            </Initializer>
          </BrowserRouter>
        </Provider>
      )}
    </>
  );
}

export default App;
