import PageContainer from "component/page-container/page-container";
import styles from "./assets/scss/welcome-screen.module.css";
import { IProps } from "./interface/welcome-screen";
import { useSelector } from "react-redux";

const WelcomeScreen = () => {
  const navigationMenu: any = useSelector(
    (state: any) => state.ui.navigationMenu
  );
  const app = useSelector((state: any) => state.app.allData);

  return (
    <PageContainer>
      <div
        style={{
          background: navigationMenu.open
            ? "#0F363B61 0% 0% no-repeat padding-box"
            : "transparent",
          transition: "background 0.3s ease",
          height: "100%",
        }}
      >
        {!navigationMenu.open && (
          <div
            className={styles.centerText}
            style={{
              textAlign: "center",
              font: "normal normal bold 36px/44px Lato",
              letterSpacing: "0px",
              color: "#EE854E",
              textShadow: "3px 9px 7px #00000029",
              opacity: "1",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Welcome {app.userName} !
          </div>
        )}
      </div>
    </PageContainer>
  );
};

export default WelcomeScreen;
