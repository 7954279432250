import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom"; // Import React Router hooks
import styles from "./assets/scss/navigation-menu.module.css";
import { useDispatch, useSelector } from "react-redux";
import { IProps } from "./interface/navigation-menu";
import { uiActions } from "../../store/ui/ui-slice";

const NavigationMenuComponent = ({ navMenuItems }: IProps) => {
  const navigationMenu: any = useSelector(
    (state: any) => state.ui.navigationMenu
  );
  const app = useSelector((state: any) => state.app.allData);

  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState<string | false>(false);
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const _BackgroundClickHandler = () => {
    dispatch(uiActions.toggleNavigationMenu());
  };
  return (
    <>
      {navigationMenu.open && (
        <div className={styles.bg} onClick={_BackgroundClickHandler}>
          {navMenuItems.map((x) => (
            <Accordion
              expanded={expanded === x.id}
              onChange={handleChange(x.id)}
              style={{
                backgroundColor: x.backgroundColor,
                width: x.width,
                marginLeft: "auto",
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              <AccordionSummary
                onClick={(event) => {
                  event.stopPropagation();
                }}
                expandIcon={
                  <KeyboardArrowDownIcon style={{ color: "#EE854E" }} />
                }
              >
                <h3 style={{ color: "#EE854E" }}>{x.title}</h3>
              </AccordionSummary>
              <AccordionDetails>
                {x.pages.map((p) => (
                  <Button
                    onClick={() => navigate(p.path)}
                    style={{ color: "#EE854E" }}
                  >
                    {p.title}
                  </Button>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </>
  );
};

export default NavigationMenuComponent;
