import * as React from "react";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import MyIcon from "./assets/img/conversation.png";
import MyIcon2 from "./assets/img/question.png";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Stack the header and card vertically
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {/* Header */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <img
          src={MyIcon}
          alt="FAQ Icon"
          style={{ width: 40, height: 40, marginRight: "8px" }}
        />{" "}
        {/* Local icon */}
        <Typography variant="h4" component="h1" align="center">
          FAQ
        </Typography>
      </Box>

      {/* Card */}
      <Card
        sx={{
          backgroundColor: "#EE854E73", // Background color for the card
          width: "90%", // Make the card responsive
          maxWidth: "600px", // Set a maximum width for the card
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow for the card
        }}
      >
        <CardContent>
          <Accordion
            style={{ backgroundColor: "#EE854E78" }}
            expanded={expanded}
            onChange={handleExpansion}
            slots={{ transition: Fade as AccordionSlots["transition"] }}
            slotProps={{ transition: { timeout: 400 } }}
            sx={[
              expanded
                ? {
                    "& .MuiAccordion-region": {
                      height: "auto",
                    },
                    "& .MuiAccordionDetails-root": {
                      display: "block",
                    },
                  }
                : {
                    "& .MuiAccordion-region": {
                      height: 0,
                    },
                    "& .MuiAccordionDetails-root": {
                      display: "none",
                    },
                  },
            ]}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                {" "}
                <img
                  src={MyIcon2}
                  alt="FAQ Icon"
                  style={{ width: 30, height: 30, marginRight: "8px" }}
                />{" "}
                {/* Local icon */}
                What is TickTrackIt?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                TickTrackIt is a time tracking tool designed to help individuals
                and teams monitor their work hours, manage projects, and improve
                productivity.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ backgroundColor: "#EE854E78" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>
                {" "}
                <img
                  src={MyIcon2}
                  alt="FAQ Icon"
                  style={{ width: 30, height: 30, marginRight: "8px" }}
                />{" "}
                {/* Local icon */}
                How do I create an account?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                To create an account, visit the Register page and fill in the
                required information. You will receive a confirmation email to
                activate your account.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </Box>
  );
}
