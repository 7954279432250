import { Button, Menu, MenuItem, TextField } from "@mui/material";
import axiosInstance from "utils/axios";
import React, { useState, useEffect } from "react";
import styles from "./assets/css/Admin.module.css";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import { _DateToISOFormat } from "../../utils/date";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageContainer from "component/page-container/page-container";
// Define types for the state and API responses
interface Project {
  id: number;
  name: string;
}
interface TimeTrackingResponse {
  isOk: boolean;
  message: string;
  code: number;
  total_time_spent: string;
  users: User[];
  first_start_time?: string; // Add this line
  last_start_time?: string; // Add this line
}

interface User {
  user_id: number;
  username: string;
  duration: string;
}

interface TimeTrackingResponse {
  isOk: boolean;
  message: string;
  code: number;
  total_time_spent: string;
  users: User[];
}

interface UsernamesResponse {
  isOk: boolean;
  message: string;
  code: number;
  users: User[];
}

interface ProjectsResponse {
  isOk: boolean;
  message: string;
  code: number;
  projects: Project[];
}

const TimeTrackingData: React.FC = () => {
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL!;

  const [projectAnchorEl, setProjectAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectIdMap, setProjectIdMap] = useState<{ [key: string]: number }>(
    {}
  );
  const [isTiming, setIsTiming] = useState(false);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [responseData, setResponseData] = useState<TimeTrackingResponse | null>(
    null
  );
  const [buttonText, setButtonText] = useState("ADD");
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const app = useSelector((state: any) => state.app.allData);

  const handleClickProject = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProjectAnchorEl(event.currentTarget);
  };

  const handleCloseProject = (project: Project | null) => {
    setProjectAnchorEl(null);
    if (project) {
      setSelectedProject(project);
      fetchDate(project.id); // Fetch date when a project is selected
    }
  };

  const fetchData = async () => {
    if (!selectedProject) return;

    setLoading(true);
    try {
      const projectId = selectedProject.id;
      const response = await axiosInstance.get<TimeTrackingResponse>(
        `${apiURL}/api/time-tracking/${projectId}/`,
        {
          params: {
            from_date: _DateToISOFormat(fromDate),
            to_date: _DateToISOFormat(toDate, true),
          },
        }
      );

      const { isOk, message } = response.data;
      if (isOk) {
        setResponseData(response.data);
        dispatch(uiActions.updateNotification({ message, level: "success" }));
      } else {
        dispatch(uiActions.updateNotification({ message, level: "error" }));
      }
    } catch (error) {
      console.error("An error occurred while returned data.", error);
      dispatch(
        uiActions.updateNotification({
          message: "An error occurred while returned data.",
          level: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchDate = async (projectId: number) => {
    try {
      const timeTrackingResponse =
        await axiosInstance.get<TimeTrackingResponse>(
          `${apiURL}/api/get_time_tracking_start_times/`,
          { params: { project_id: projectId } }
        );

      const { isOk, first_start_time, last_start_time } =
        timeTrackingResponse.data;

      if (isOk) {
        if (first_start_time && last_start_time) {
          // Ensure these properties are defined
          setFromDate(first_start_time.split("T")[0]); // Extract date portion
          setToDate(last_start_time.split("T")[0]); // Extract date portion
        }
      }
    } catch (error) {
      console.error("No tasks done in this project.:", error);
      dispatch(
        uiActions.updateNotification({
          message: "No tasks done in this project.",
          level: "error",
        })
      );
    }
  };

  useEffect(() => {
    const fetchUsernames = async () => {
      try {
        const response = await axiosInstance.post<UsernamesResponse>(
          `${apiURL}/api/get_all_usernames/`,
          { entityId: app.entityId }
        );
        const { isOk, message, users } = response.data;
        if (isOk && users) {
          const idMap: { [key: string]: number } = {};
          users.forEach((user) => {
            idMap[user.username] = user.user_id; // Make sure to use correct property for user_id
          });
          setProjectIdMap(idMap);
        } else {
          dispatch(uiActions.updateNotification({ message, level: "error" }));
        }
      } catch (error) {
        console.error("An error occurred while returned usernames. ", error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.post<ProjectsResponse>(
          `${apiURL}/api/get_all_projects/`,
          { entityId: app.entityId }
        );
        const { isOk, message, projects } = response.data;
        if (isOk && projects) {
          setProjectList(projects);
          const idMap: { [key: string]: number } = {};
          projects.forEach((project) => {
            idMap[project.name] = project.id;
          });
          setProjectIdMap(idMap);
        } else {
          dispatch(uiActions.updateNotification({ message, level: "error" }));
        }
      } catch (error) {
        console.error("An error occurred while returned projects.", error);
      }
    };

    fetchUsernames();
    fetchProjects();
  }, [apiURL, app.entityId, dispatch]);

  return (
    <PageContainer>
      {" "}
      <Card className={styles.card}>
        <CardContent>
          <div className={styles.menuContainer}>
            <Button
              className={styles.menu}
              id="project-button"
              aria-controls={projectAnchorEl ? "project-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={!!projectAnchorEl}
              onClick={handleClickProject}
              disabled={isTiming}
            >
              {selectedProject ? selectedProject.name : "Select Project"}
            </Button>
          </div>
          <div className={styles.buttonContainer}>
            <Menu
              id="project-menu"
              anchorEl={projectAnchorEl}
              open={Boolean(projectAnchorEl)}
              onClose={() => handleCloseProject(null)}
              MenuListProps={{
                "aria-labelledby": "project-button",
              }}
            >
              {projectList.map((project) => (
                <MenuItem
                  style={{ width: "250px" }}
                  className={styles.me}
                  key={project.id}
                  onClick={() => handleCloseProject(project)}
                >
                  {project.name}
                </MenuItem>
              ))}
            </Menu>
            <Button
              variant="contained"
              color="primary"
              onClick={fetchData}
              className={styles.btnzz}
              disabled={!selectedProject || !fromDate || !toDate || loading} // Disable if no project or dates are selected or if loading
            >
              {loading ? "Loading..." : "Time Tracking Data"}
            </Button>
          </div>
          <div className={styles.dateContainer}>
            <TextField
              label="From Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className={styles.datePicker}
            />
            <TextField
              label="To Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className={styles.datePicker}
            />
          </div>
          {responseData && (
            <div className={styles.card2}>
              <p className={styles.totalTime}>
                Total Time Spent: {responseData.total_time_spent}
              </p>
              <ul className={styles.dataList}>
                {responseData.users.map((user) => (
                  <li className={styles.dataItem} key={user.user_id}>
                    <span className={styles.dot}></span>
                    <span className={styles.username}>
                      Username: {user.username}
                    </span>
                    ,
                    <span className={styles.duration}>
                      {" "}
                      Duration: {user.duration}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default TimeTrackingData;
