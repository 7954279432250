import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    loading: false,
    notification: {
      level: "",
      message: "",
    },
    navigationMenu: {
      open: false,
    },
    showButton: true,
  },
  reducers: {
    updateNotification(state, action) {
      state.notification.level = action.payload.level;
      state.notification.message = action.payload.message;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setNavigationMenu(state, action) {
      state.navigationMenu = action.payload;
    },
    toggleNavigationMenu(state) {
      state.navigationMenu.open = !state.navigationMenu.open;
    },
    setShowButton(state, action) {
      state.showButton = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
