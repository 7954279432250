import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "utils/axios";
import styles from "./assets/css/Edit.module.css";
import PageContainer from "component/page-container/page-container";

interface Member {
  member_id: number;
  member_username: string;
  member_email: string;
  member_role: string;
}

const Edit: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectName, members, id } = location.state;
  const [projectNameState, setProjectName] = useState<string>(projectName);
  const [membersState, setMembers] = useState<Member[]>(members);
  const [unassignedMembers, setUnassignedMembers] = useState<number[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [hasChanges, setHasChanges] = useState<boolean>(false); // Track changes
  const apiURL = process.env.REACT_APP_API_URL || "";
  const dispatch = useDispatch();

  const handleUnassign = (memberId: number) => {
    setMembers((prevMembers) =>
      prevMembers.filter((member) => member.member_id !== memberId)
    );

    if (!unassignedMembers.includes(memberId)) {
      setUnassignedMembers((prev) => [...prev, memberId]);
    }

    setHasChanges(true); // Mark as changed
  };

  const handleProjectNameChange = (value: string) => {
    setProjectName(value);
    if (value !== projectName) {
      setHasChanges(true); // Mark as changed
    }
  };

  const handleSave = async () => {
    try {
      const promises = [];

      if (unassignedMembers.length > 0) {
        const unassignPromise = axiosInstance.post(
          `${apiURL}/api/unassign_member/`,
          {
            project_id: id,
            member_ids: unassignedMembers,
          }
        );
        promises.push(unassignPromise);
      }

      const namePromise = axiosInstance.post(
        `${apiURL}/api/update-project-name/`,
        {
          project_id: id,
          name: projectNameState,
        }
      );
      promises.push(namePromise);

      const results = await Promise.all(promises);

      console.log("API responses:", results);

      if (unassignedMembers.length > 0) {
        const unassignResponse = results[0];
        console.log("Unassign response:", unassignResponse);

        if (unassignResponse.status === 200) {
          dispatch(
            uiActions.updateNotification({
              message: "Members unassigned successfully",
              level: "success",
            })
          );
        } else {
          throw new Error("Failed to unassign members");
        }
      }

      const nameResponse = results[results.length - 1];
      console.log("Name update response:", nameResponse);

      if (nameResponse.status === 200) {
        dispatch(
          uiActions.updateNotification({
            message: "Done successfully",
            level: "success",
          })
        );
      } else {
        throw new Error("Failed to update project name");
      }
    } catch (error) {
      console.error("Error updating project:", error);
      dispatch(
        uiActions.updateNotification({
          message: "Error updating project. Please try again.",
          level: "error",
        })
      );
    } finally {
      setHasChanges(false); // Reset change tracking after saving
    }
  };

  const handleCancel = () => {
    setProjectName(projectName);
    setMembers(members);
    setUnassignedMembers([]);
    setHasChanges(false); // Reset changes
    navigate("../Admin/All");
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <PageContainer>
      {" "}
      <Box className={styles.container}>
        <Typography variant="h4" component="h1" className={styles.title}>
          Project Details
        </Typography>

        <TextField
          label="Project Name"
          variant="outlined"
          className={styles.txt}
          value={projectNameState}
          onChange={(e) => handleProjectNameChange(e.target.value)}
          sx={{
            mb: 4,
            width: "40%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent",
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#245648",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#245648",
            },
          }}
        />

        <Paper className={styles.card}>
          <Typography
            variant="h5"
            component="h2"
            sx={{
              textAlign: "left",
              fontWeight: "bold",
              color: "#0F363B",
              opacity: 1,
              padding: "16px",
            }}
          >
            Members
          </Typography>

          <TableContainer
            style={{ backgroundColor: "#F3ECE6" }}
            component={Paper}
            sx={{ mb: 4, width: "100%" }}
          >
            <Table>
              <TableHead>
                <TableRow className={styles.tableHeader}>
                  <TableCell>Member Name</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {membersState.map((member) => (
                  <TableRow key={member.member_id} className={styles.tableRow}>
                    <TableCell>{member.member_username}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        className={styles.unassignButton}
                        onClick={() => handleUnassign(member.member_id)}
                      >
                        Unassign
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              padding: "16px 0",
            }}
          >
            <Button
              variant="contained"
              className={styles.saveButton}
              onClick={handleSave}
              disabled={!hasChanges} // Disable if no changes
              sx={{ margin: "0 10px" }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              className={styles.cancelButton}
              onClick={handleCancel}
              sx={{ margin: "0 10px" }}
            >
              Cancel
            </Button>
          </Box>
        </Paper>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarMessage.includes("Error") ? "error" : "success"}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </PageContainer>
  );
};

export default Edit;
