import React, { useState, useEffect } from "react";
// redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { appActions } from "../store/app/app";

const apiURL = process.env.REACT_APP_API_URL;

type Props = {
  children: React.ReactNode;
};
const Initializer = ({ children }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [InitializerLoaded, setInitializerLoaded] = useState(false);

  useEffect(() => {
    async function checkTokenValidity() {
      try {
        const tkn = localStorage.getItem("token");

        // if (!tkn) {
        //   navigate("/Login");
        //   setInitializerLoaded(true);
        //   return;
        // }

        // const response = await axios.get(
        //   `${apiURL}/api/check-token/?token=${tkn}`
        // );
        // const tokenData = response.data;

        // if (tokenData.expired || tokenData.error || !tokenData.valid) {
        //   localStorage.removeItem("token");
        //   navigate("/login");
        // }

        const res = await axios.get(`${apiURL}/api/initialize/?token=${tkn}`);
        const {
          isOk,
          message,
          code,
          token,
          userId,
          entityId,
          email: userEmail,
          entityName,
          userName,
          role,
          is_temp,
        } = res.data;
        if (isOk) {
          dispatch(
            appActions.setAppData({
              userId: userId,
              entityId: entityId,
              entityName: entityName,
              userName: userName,
              email: userEmail,
              is_temp: is_temp,
            })
          );
          if (role === "admin") {
            navigate("../Admin");
          } else {
            navigate("../User");
          }
        }
        setInitializerLoaded(true);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    }

    checkTokenValidity();
  }, []);
  return <>{InitializerLoaded ? children : <div />}</>;
};
export default Initializer;
