import Button from "@mui/material/Button";
import axiosInstance from "utils/axios";
import * as React from "react";
import { useState } from "react";
import styles from "./assets/scss/Profile.module.css";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { uiActions } from "../../store/ui/ui-slice";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "component/page-container/page-container";
import CircularProgress from "@mui/material/CircularProgress";
import RHFTextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// Define types for response data
const Profile = () => {
  const apiURL = process.env.REACT_APP_API_URL || "https://your-api-url"; // Fallback URL

  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerificationStep, setIsVerificationStep] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const app = useSelector((state: any) => state.app.allData);

  console.log("id: ", app.userId);

  const handleCreate = () => {
    if (newPassword !== confirmPassword) {
      dispatch(
        uiActions.updateNotification({
          message: "Passwords do not match",
          level: "error",
        })
      );
      return;
    }

    const requestData = {
      memberId: app.userId,
      newPassword,
      oldPassword,
    };

    setLoading(true); // Set loading to true before API call

    axiosInstance
      .post(`${apiURL}/api/update_password/`, requestData)
      .then((response) => {
        console.log("Success:", response.data);
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: response.data.isOk ? "success" : "error",
          })
        );
        setLoading(false); // Reset loading to false after API response

        // Clear fields after success
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch(() => {
        setLoading(false); // Reset loading state if error occurs
      });
  };

  const handleMail = () => {
    const requestData = {
      userId: app.userId,
      newEmail: email,
    };

    setLoading(true); // Set loading to true before API call

    axiosInstance
      .post(`${apiURL}/api/update_email/`, requestData)
      .then((response) => {
        console.log("Success:", response.data);
        if (response.data.isOk) {
          setIsVerificationStep(true);
        }
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: response.data.isOk ? "success" : "error",
          })
        );
        setLoading(false); // Reset loading to false after API response

        // Clear the email field after success
        setEmail("");
      })
      .catch(() => {
        setLoading(false); // Reset loading state if error occurs
      });
  };

  const handleVerification = () => {
    const requestData = {
      userId: app.userId,
      verificationCode,
    };

    setLoading(true); // Set loading to true before API call

    axiosInstance
      .post(`${apiURL}/api/verify_email_change/`, requestData)
      .then((response) => {
        console.log("Success:", response.data);
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: response.data.isOk ? "success" : "error",
          })
        );
        if (response.data.isOk) {
          setIsVerificationStep(false);
        }
        setLoading(false); // Reset loading to false after API response

        // Clear the verification code field after success
        setVerificationCode("");
      })
      .catch(() => {
        setLoading(false); // Reset loading state if error occurs
      });
  };

  return (
    <PageContainer>
      <Card className={styles.card}>
        <CardContent className={styles.cardContent}>
          {!isVerificationStep ? (
            <>
              {/* Password Section */}
              <div className={styles.passwordSection}>
                <RHFTextField
                  name="oldPassword"
                  label="Old Password"
                  type={showOldPassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  sx={{ backgroundColor: "#2963545C" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowOldPassword(!showOldPassword)}
                          edge="end"
                        >
                          {showOldPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <RHFTextField
                  name="newPassword"
                  label="New Password"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  sx={{ backgroundColor: "#2963545C" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          edge="end"
                        >
                          {showNewPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <RHFTextField
                  name="confirmPassword"
                  label="Confirm New Password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  sx={{ backgroundColor: "#2963545C" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  className={styles.bt}
                  variant="contained"
                  color="secondary"
                  onClick={handleCreate}
                  disabled={
                    !newPassword || !oldPassword || !confirmPassword || loading
                  }
                >
                  {loading ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : (
                    "Update Password"
                  )}
                </Button>
              </div>

              {/* Email Section */}
              <div className={styles.emailSection}>
                <TextField
                  className={styles.txt4}
                  label="New Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  className={styles.bt2}
                  variant="contained"
                  color="secondary"
                  onClick={handleMail}
                  disabled={!email || loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : (
                    "Update Email"
                  )}
                </Button>
              </div>
            </>
          ) : (
            <div className={styles.verificationSection}>
              <TextField
                className={styles.txt}
                label="Verification Code"
                variant="outlined"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <Button
                className={styles.bt}
                variant="contained"
                color="secondary"
                onClick={handleVerification}
                disabled={!verificationCode || loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  "Verify Email"
                )}
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default Profile;
