import Header from "pages/Header/Header";
import NavigationMenu from "pages/navigation-menu/index";
import style from "./assets/css/Layout.module.css";

interface IProps {
  children: any;
  menuIconVisible: boolean;
}

const Layout = ({ children, menuIconVisible }: IProps) => {
  const handleLogout = () => {
    console.log("Logging out...");
  };

  return (
    <div className={style.layout}>
      <Header handleLogout={handleLogout} menuIconVisible={menuIconVisible} />
      <NavigationMenu />
      <div className={style.content}>{children}</div>
    </div>
  );
};

export default Layout;
