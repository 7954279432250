import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import axiosInstance from "utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import styles from "./assets/css/Approve.module.css";
import Box from "@mui/material/Box";
import PageContainer from "component/page-container/page-container";

interface Member {
  id: number;
  name: string;
}

interface Project {
  id: number;
  name: string;
}

const Approvetime: React.FC = () => {
  const [projectId, setProjectId] = useState<number | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [usernames, setUsernames] = useState<Member[]>([]);
  const [memberIdMap, setMemberIdMap] = useState<{ [name: string]: number }>(
    {}
  );
  const [projectIdMap, setProjectIdMap] = useState<{ [name: string]: number }>(
    {}
  );
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [buttonText, setButtonText] = useState<string>("Display");
  const [responseData, setResponseData] = useState<{ data: any[] } | null>(
    null
  );

  const { entityId } = useSelector((state: any) => state.app.allData); // Adjust type as per your store structure
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL || "";

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiURL}/api/projects/unapproved-time-entries/`,
          { params: { entity_id: entityId } }
        );
        const { isOk, message, projects } = response.data;

        if (isOk && projects) {
          setProjectList(projects);
          const idMap: { [name: string]: number } = {};
          projects.forEach((project: Project) => {
            idMap[project.name] = project.id;
          });
          setProjectIdMap(idMap);
        } else {
          dispatch(uiActions.updateNotification({ message, level: "error" }));
        }
      } catch (error: any) {
        console.error("Error fetching projects:", error.message);
      }
    };

    fetchProjects();
  }, [dispatch, apiURL, entityId]);

  const fetchUsernames = async (projectId: number) => {
    try {
      const response = await axiosInstance.post(
        "/api/list_members_with_unapproved_time_entries/",
        { projectId, entityId }
      );
      const { isOk, message, members } = response.data;
      if (isOk && members) {
        const idMap: { [name: string]: number } = {};
        members.forEach((member: Member) => {
          idMap[member.name] = member.id;
        });
        setUsernames(members);
        setMemberIdMap(idMap);
      } else {
        dispatch(uiActions.updateNotification({ message, level: "error" }));
      }
    } catch (error: any) {
      console.error("Error fetching usernames:", error.message);
    }
  };

  const handleAdd = () => {
    if (!selectedProject || !selectedMember) {
      console.error("Please select a project and a member.");
      return;
    }

    const projectId = projectIdMap[selectedProject.name];
    const memberId = selectedMember.id;

    axiosInstance
      .get(`${apiURL}/api/approve_details/`, {
        params: { member_id: memberId, project_id: projectId },
      })
      .then((response) => {
        setResponseData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleApprove = (entryId: number) => {
    axiosInstance
      .get(`/api/approve/?pk=${entryId}`)
      .then(() => {
        setResponseData((prevData) => ({
          ...prevData!,
          data: prevData!.data.filter((entry) => entry.pk !== entryId),
        }));
      })
      .catch((error) => {
        console.error("Error approving entry:", error);
      });
  };

  const handleDecline = (entryId: number) => {
    axiosInstance
      .get(`/api/disapprove/?pk=${entryId}`)
      .then(() => {
        setResponseData((prevData) => ({
          ...prevData!,
          data: prevData!.data.filter((entry) => entry.pk !== entryId),
        }));
      })
      .catch((error) => {
        console.error("Error declining entry:", error);
      });
  };

  return (
    <PageContainer>
      {" "}
      <Card className={styles.card}>
        <CardContent>
          {/* <Typography variant="h5" gutterBottom>
          Approve Time Entries
        </Typography> */}
          <Select
            className={styles.menu}
            value={selectedProject ? selectedProject.name : ""}
            onChange={(event) => {
              const projectName = event.target.value;
              const projectId = projectIdMap[projectName];
              setSelectedProject({ name: projectName, id: projectId });
              fetchUsernames(projectId);
            }}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Project
            </MenuItem>
            {projectList.map((project) => (
              <MenuItem key={project.id} value={project.name}>
                {project.name}
              </MenuItem>
            ))}
          </Select>

          <Select
            className={styles.menu1}
            value={selectedMember ? selectedMember.name : ""}
            onChange={(event) => {
              const memberName = event.target.value;
              const memberId = memberIdMap[memberName];
              setSelectedMember({ name: memberName, id: memberId });
            }}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Member
            </MenuItem>
            {usernames.map((member) => (
              <MenuItem key={member.id} value={member.name}>
                {member.name}
              </MenuItem>
            ))}
          </Select>
          <Box className="button-container">
            <Button
              className={styles.btn}
              variant="contained"
              onClick={handleAdd}
              disabled={!selectedProject || !selectedMember} // Disable button if either value is not selected
            >
              {buttonText}
            </Button>
          </Box>

          {responseData && responseData.data.length > 0 && (
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Duration</th>
                  <th>Task</th>
                  <th>Approve</th>
                  <th>Decline</th>
                  <th>Automatic</th>
                </tr>
              </thead>
              <tbody>
                {responseData.data.map((entry, index) => (
                  <tr
                    key={index}
                    className={entry.auto ? styles.autoRow : styles.manualRow} // Apply styles dynamically
                  >
                    <td>{new Date(entry.startTime).toLocaleString()}</td>
                    <td>{new Date(entry.endTime).toLocaleString()}</td>
                    <td>{entry.duration}</td>
                    <td>{entry.task}</td>
                    <td>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleApprove(entry.pk)}
                      >
                        Approve
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDecline(entry.pk)}
                      >
                        Decline
                      </Button>
                    </td>
                    <td>{entry.auto.toString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default Approvetime;
