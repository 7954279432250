import React from "react";
import styles from "./assets/scss/timer.module.css";

interface TimerProps {
  startTime: string;
  imageSrc: string; // Image source
  altText: string; // Alt text for the image
}

const Timer: React.FC<TimerProps> = ({ startTime, imageSrc, altText }) => {
  return (
    <div className={styles.timerContainer}>
      <div className={styles.timerBox}>
        <span>{startTime}</span>
      </div>
      <img className={styles.timerImage} src={imageSrc} alt={altText} />
    </div>
  );
};

export default Timer;
