import { MenuItem, Select, Button, SelectChangeEvent } from "@mui/material";
import axiosInstance from "utils/axios";
import * as React from "react";
import styles from "./assets/scss/Admin.module.css";
// redux
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageContainer from "component/page-container/page-container";
interface AppData {
  entityId: string; // Adjust type as per your actual app state
}

const apiURL = process.env.REACT_APP_API_URL;

const Reset: React.FC = () => {
  const dispatch = useDispatch();
  const app = useSelector(
    (state: { app: { allData: AppData } }) => state.app.allData
  );
  const [selectedCode, setSelectedCode] = React.useState<number | string>("");
  const [loading, setLoading] = React.useState(false); // New state to track API request status

  const handleResetAll = async () => {
    try {
      setLoading(true); // Set loading to true when the API request starts
      const response = await axiosInstance.post(
        `${apiURL}/api/truncate-tables/`,
        {
          entityId: app.entityId,
          code: selectedCode,
        }
      );
      const { isOk, message } = response.data;
      dispatch(
        uiActions.updateNotification({
          message: message,
          level: isOk ? "success" : "error",
        })
      );
    } catch (error) {
      console.error("Error during reset:", error);
      dispatch(
        uiActions.updateNotification({
          message: "An error occurred during the reset.",
          level: "error",
        })
      );
    } finally {
      setLoading(false); // Reset loading state when the request is complete (either success or error)
    }
  };

  const handleSelectionChange = (event: SelectChangeEvent<number | string>) => {
    setSelectedCode(event.target.value as number);
  };

  const handleResetConfirmation = () => {
    if (selectedCode === "") {
      dispatch(
        uiActions.updateNotification({
          message: "Please select an option from the menu.",
          level: "warning",
        })
      );
      return;
    }
    if (window.confirm("Are you sure you want to reset?")) {
      handleResetAll();
    }
  };

  return (
    <PageContainer>
      {" "}
      <Card className={styles.card}>
        <CardContent className={styles.menuContainer}>
          <Select
            value={selectedCode}
            onChange={handleSelectionChange}
            displayEmpty
            className={styles.menu}
          >
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            <MenuItem value={-1}>Reset All</MenuItem>
            <MenuItem value={1}>Time Tracking</MenuItem>
            <MenuItem value={2}>Project Assignment</MenuItem>
            <MenuItem value={3}>Projects</MenuItem>
            <MenuItem value={4}>Members</MenuItem>
          </Select>
          <Button
            className={styles.btn5}
            variant="contained"
            color="secondary"
            onClick={handleResetConfirmation}
            disabled={loading} // Disable the button if loading is true
          >
            {loading ? "Resetting..." : "Submit"}{" "}
            {/* Show a different label when loading */}
          </Button>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default Reset;
