import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axios";
import styles from "./assets/css/AllUsers.module.css";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageContainer from "component/page-container/page-container";
interface Member {
  member_id: number;
  member_name: string;
  member_status: string;
}

const AllUsers: React.FC = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const apiURL = process.env.REACT_APP_API_URL || ""; // Load API URL from environment variables
  const navigate = useNavigate(); // Initialize useNavigate for routing
  const { entityId } = useSelector((state: any) => state.app.allData);

  // Function to fetch members from API
  const fetchMembers = async () => {
    try {
      const response = await axiosInstance.post(`${apiURL}/api/list-members/`, {
        entity_id: entityId,
      });
      if (response.data.isOk && response.data.code === "SUCCESS") {
        setMembers(response.data.members);
      }
    } catch (error) {
      console.error("Error fetching members", error);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, [apiURL]);

  const handleEdit = (member: Member) => {
    navigate(`../Admin/edit-member`, {
      state: {
        memberName: member.member_name,
        memberStatus: member.member_status,
        id: member.member_id,
      },
    });
  };

  return (
    <PageContainer>
      <Card className={styles.card}>
        <CardContent>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table className={styles.table}>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell className={styles.tableHeadCell}>Member</TableCell>
                  <TableCell className={styles.tableHeadCell}>Status</TableCell>
                  <TableCell className={styles.tableHeadCell}>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members.map((member) => (
                  <TableRow
                    key={member.member_id}
                    className={styles.tableBodyRow}
                  >
                    <TableCell className={styles.tableCell} data-label="Member">
                      {member.member_name}
                    </TableCell>
                    <TableCell className={styles.tableCell} data-label="Status">
                      {member.member_status}
                    </TableCell>

                    <TableCell className={styles.tableCell} data-label="Edit">
                      <Button
                        variant="contained"
                        className={styles.editButton}
                        onClick={() => handleEdit(member)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default AllUsers;
