import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import { SelectChangeEvent } from "@mui/material";
import {
  Button,
  TextField,
  Typography,
  Box,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "utils/axios";
import styles from "./assets/css/EditUsers.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageContainer from "component/page-container/page-container";

interface Member {
  member_id: number;
  member_username: string;
  member_email: string;
  member_role: string;
  member_status: number;
}

const EditUsers: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { memberName, members, id } = location.state;
  const [selectedCode, setSelectedCode] = useState<number | string>("");
  const [UserNameState, setUserName] = useState<string>(memberName);
  const [membersState] = useState<Member[]>(members || []);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const apiURL = process.env.REACT_APP_API_URL || "";
  const dispatch = useDispatch();

  // Track changes in username or status
  useEffect(() => {
    const isChanged = UserNameState !== memberName || selectedCode !== "";
    setIsSaveEnabled(isChanged);
  }, [UserNameState, selectedCode, memberName]);

  const handleSave = async () => {
    try {
      await Promise.all([
        axiosInstance.post(`${apiURL}/api/update-name/`, {
          member_id: id,
          name: UserNameState,
        }),
        axiosInstance.post(`${apiURL}/api/update-status/`, {
          member_id: id,
          status_id: selectedCode,
        }),
      ]);

      dispatch(
        uiActions.updateNotification({
          message: "Members updated successfully",
          level: "success",
        })
      );
      setSnackbarMessage("Members updated successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating members:", error);
      dispatch(
        uiActions.updateNotification({
          message: "Error updating members. Please try again.",
          level: "error",
        })
      );
      setSnackbarMessage("Error updating members. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleCancel = () => {
    navigate("../Admin/AllUsers");
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSelectionChange = (event: SelectChangeEvent<number | string>) => {
    setSelectedCode(event.target.value as number);
  };

  return (
    <PageContainer>
      {" "}
      <Card className={styles.card}>
        <CardContent>
          <Box className={styles.container}>
            <Typography variant="h4" component="h1" className={styles.title}>
              Users Details
            </Typography>

            <TextField
              label="User Name"
              variant="outlined"
              className={styles.txt}
              value={UserNameState}
              onChange={(e) => setUserName(e.target.value)}
              sx={{
                mb: 4,
                width: "40%",
                "& .MuiOutlinedInput-root fieldset": {
                  borderColor: "transparent",
                },
                "& .MuiInputLabel-root": { color: "#245648" },
              }}
            />

            <Select
              value={selectedCode}
              onChange={handleSelectionChange}
              displayEmpty
              className={styles.menu}
            >
              <MenuItem value="" disabled>
                Select a status
              </MenuItem>
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={2}>Unverified</MenuItem>
              <MenuItem value={3}>Blocked</MenuItem>
            </Select>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                padding: "16px 0",
              }}
            >
              <Button
                variant="contained"
                className={styles.saveButton}
                onClick={handleSave}
                disabled={!isSaveEnabled}
                sx={{ margin: "0 10px" }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                className={styles.cancelButton}
                onClick={handleCancel}
                sx={{ margin: "0 10px" }}
              >
                Cancel
              </Button>
            </Box>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={
                  snackbarMessage.includes("Error") ? "error" : "success"
                }
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default EditUsers;
