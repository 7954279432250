import React from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
// icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// components
import FormProvider from "../../component/hook-form/form-provider";
import RHFTextField from "../../component/hook-form/rhf-text-field";
// routing
import { useNavigate } from "react-router-dom";
// utils
import axiosInstance from "utils/axios";
// hooks
import { useBoolean } from "../../hooks/use-boolean";
// Redux
import { uiActions } from "../../store/ui/ui-slice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
enum Role {
  Admin = "admin",
  User = "user",
}
export default function Change_temp_pass() {
  const navigate = useNavigate();
  const password = useBoolean();
  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    password: Yup.string().required("Password is required").min(8),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
    temporaryPassword: Yup.string()
      .required("Temporary password is required")
      .min(8, "Temporary password must be at least 8 characters"),
  });

  const app = useSelector((state: any) => state.app.allData);
  const apiURL = process.env.REACT_APP_API_URL;

  const defaultValues = {
    password: "",
    confirmPassword: "",
    temporaryPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const response = await axiosInstance.post(
      `${apiURL}/api/update_temp_password/`,
      {
        memberId: app.userId,
        oldPassword: data.temporaryPassword,
        newPassword: data.password,
      }
    );
    const { isOk, message, role } = response.data;
    if (isOk) {
      if (role === Role.Admin) {
        navigate("/Admin");
      } else if (role === Role.User) {
        navigate("/User");
      }
      dispatch(
        uiActions.updateNotification({
          message: response.data.message,
          level: response.data.isOk ? "success" : "error",
        })
      );
    } else {
      dispatch(
        uiActions.updateNotification({
          message: message,
          level: "error",
        })
      );
    }
  });

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        <RHFTextField
          name="temporaryPassword"
          label="Temporary Password"
          sx={{ backgroundColor: "#2963545C" }}
          type={password.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  {password.value ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="password"
          label="Password"
          sx={{ backgroundColor: "#2963545C" }}
          type={password.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  {password.value ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="confirmPassword"
          label="Confirm Password"
          sx={{ backgroundColor: "#2963545C" }}
          type={password.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  {password.value ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            backgroundColor: "#EE854E",
            "&:hover": { backgroundColor: "#EE854E" },
          }}
        >
          Change Password
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (
    <div
      style={{
        maxWidth: "90%",
        width: "400px",
        margin: "50px auto",
        padding: "20px",
        backgroundColor: "#F3ECE6",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {renderForm}
    </div>
  );
}
